import {Component, Inject, OnInit,} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {filter, mergeMap, tap} from "rxjs";
import {ApiService} from "../../api/api.service";

@Component({
  selector: 'app-unsubscribe',
  standalone: true,
  imports: [],
  templateUrl: './unsubscribe.component.html',
  styleUrl: './unsubscribe.component.scss',
})
export class UnsubscribeComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly apiService: ApiService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      filter((params) => Boolean('userId' in params)),
      filter((params) => Boolean('token' in params)),
      mergeMap((params: {userId: string, token: string}) => this.apiService.unsubscribe(params.userId, params.token))
    ).subscribe()
  }
}
