import { CommonModule } from '@angular/common';
import { Component, Output, EventEmitter, ViewChildren, QueryList, ElementRef, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import {TranslocoModule} from "@ngneat/transloco";


@Component({
  selector: 'app-six-digit-code-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskDirective,
    ReactiveFormsModule,
    TranslocoModule
  ],
  providers: [provideNgxMask()],
  templateUrl: './six-digit-code-input.component.html',
  styleUrl: './six-digit-code-input.component.scss'
})
export class SixDigitCodeInputComponent implements OnInit {
  public codeForm: FormGroup;
  public codeControls: string[] = Array(6).fill('');
  @Input() isVerifyCodeValid: boolean = false;
  @Output() codeEntered: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren('codeInput') codeInputs: QueryList<ElementRef>;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
  }

  public onInput(index: number, event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length === 1 && index < this.codeControls.length - 1) {
      this.codeInputs.toArray()[index + 1].nativeElement.focus();
      this.codeInputs.toArray()[index + 1].nativeElement.select();
    } else if (input.value.length === 1 && index === this.codeControls.length - 1) {
      if (this.allInputsFilled()) {
        this.send();
      }
    }
  }

  public onKeyDown(index: number, event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    if (event.key === 'Backspace' && !input.value && index > 0) {
      this.codeInputs.toArray()[index - 1].nativeElement.focus();
    }
  }

  public onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    if (pastedText.length === this.codeControls.length && /^\d+$/.test(pastedText)) {
      for (let i = 0; i < this.codeControls.length; i++) {
        this.codeForm.controls[i.toString()].setValue(pastedText.charAt(i));
      }
      this.send();
    }
    event.preventDefault();
  }

  private initForm(): void {
    this.codeForm = this.formBuilder.group(
      this.codeControls.reduce((controls, value, index) => {
        controls[index] = new FormControl('', [Validators.required]);
        return controls;
      }, {})
    );
  }

  public send(): void {
    const codeString = Object.values(this.codeForm.value).join('');
    this.codeEntered.emit(codeString);
  }

  private allInputsFilled(): boolean {
    return Object.values(this.codeForm.controls).every(control => control.value !== '');
  }

}
