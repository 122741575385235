import { Component, ViewChild, TemplateRef, inject, Output, EventEmitter } from '@angular/core';
import { CommonModule } from "@angular/common";
import { SixDigitCodeInputComponent } from '../six-digit-code-input/six-digit-code-input.component';
import { ApiService } from 'src/app/api/api.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { passwordMatchValidator, passwordValidator } from 'src/app/utils/validators';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import { NoSpacesDirective } from 'src/app/directives/no-spaces.directive';
import {TranslocoModule} from "@ngneat/transloco";
enum RecoverStep {
  email,
  code,
  password,
  done
}
@Component({
  selector: 'app-recover-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SixDigitCodeInputComponent,
    IconsComponent,
    NoSpacesDirective,
    TranslocoModule
  ],
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.scss'
})
export class RecoverPasswordComponent {
  public isProcessing: boolean = false;
  public isPasswordVisible = true;
  public isConfirmPasswordVisible = true;
  public isVerifyCodeValid = false;

  @ViewChild('email') email!: TemplateRef<any>;
  @ViewChild('code') code!: TemplateRef<any>;
  @ViewChild('password') password!: TemplateRef<any>;
  @ViewChild('done') done!: TemplateRef<any>;
  @Output() onClose = new EventEmitter<any>();

  private confirmationCode: string;
  private currentStep = RecoverStep.email;
  private api = inject(ApiService);
  private toastr = inject(ToastrService);
  private formBuilder = inject(FormBuilder);

  emailForm: FormGroup = this.formBuilder.group({
    email: new FormControl('', {
      validators: [
        Validators.required,
        Validators.email,
      ]
    })
  })

  passwordForm = new FormGroup({
    password: new FormControl(null, [Validators.required, passwordValidator()]),
    confirmPassword: new FormControl(null, Validators.required)
  }, { validators: passwordMatchValidator });

  get currentStepTemplate(): TemplateRef<any> {
    switch (this.currentStep) {
      case RecoverStep.email:
        return this.email;
      case RecoverStep.code:
        return this.code;
      case RecoverStep.password:
        return this.password;
      case RecoverStep.done:
        return this.done;
    }
  }

  public sendEmail(): void {
    this.api.getRefreshPasswordCode(this.emailForm.value)
      .subscribe({
        next: (data) => {
          this.toastr.success('The code has been sent to the mail!');
          this.currentStep++
        },
        error: (err) => {
          this.toastr.error(`User not found`);
        },
        complete: () => {}
      });
  }

  public handleCodeEntered(code: string): void {
    this.confirmationCode = code;
    console.log({code: code, email: this.emailForm.value.email});

    this.api.isCorrectVerifyCode({code: code, email: this.emailForm.value.email})
    .subscribe({
      next:((data) => {
        this.isVerifyCodeValid = true;
        this.currentStep++;
      }),
      error:((err) => {
        this.toastr.error(`${err.error.message}`)
      }),
      complete:(() => {}),
    })
  }

  public createNewPassword(): void {
    const body = {
      email: this.emailForm.value.email,
      code: this.confirmationCode,
      newPassword: this.passwordForm.value.password
    }

    this.api.createNewPassword(body)
      .subscribe({
        next: (data) => {
          this.toastr.success('Password changed successfully!');
          this.currentStep++
        },
        error: (err) => {
          this.toastr.error(`${err.error.error}`);
        },
        complete: () => {}
      });
  }

  public resetState(): void {
    this.currentStep = RecoverStep.email;
    this.emailForm.reset();
    this.passwordForm.reset();
  }
}

