import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Translation,
  TranslocoLoader,
} from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class TransLocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json?${new Date().getTime()}`);
  }
}
