<main id="signup">
  <section id="signup-desktop">
    <div id="signup-background">
      <div class="left">
        <ng-container *ngTemplateOutlet="logoIcon"></ng-container>
      </div>
    </div>
    <div id="signup-content">
      <aside id="signup-left" class="signup-box">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </aside>
      <section id="signup-form" class="signup-box">
        <ng-container *ngTemplateOutlet="mode == 0 ? signInFormTemplate : sinupFormTemplate"></ng-container>
      </section>
    </div>
  </section>
  <section id="signup-mobile">
    <header id="signup-mobile-header">
      <ng-container *ngTemplateOutlet="logoMobile"></ng-container>
    </header>
    <section id="signup-mobile-content">
      <ng-container *ngTemplateOutlet="mode == 0 ? signInFormTemplate : sinupFormTemplate"></ng-container>
    </section>
  </section>
</main>

<ng-template #logoIcon>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.15209 15.561C6.62767 13.0321 7.03858 8.52582 10.0711 5.49332C13.105 2.45944 17.6139 2.04954 20.1422 4.57776L15.5669 9.153C18.0956 6.62757 22.6026 7.03821 25.6355 10.0711C28.6693 13.105 29.0793 17.6139 26.551 20.1422L21.9749 15.566C24.5013 18.0946 24.0909 22.6023 21.0577 25.6355C18.0238 28.6693 13.5149 29.0793 10.9866 26.551L15.5615 21.9762C13.0327 24.5012 8.52601 24.0904 5.49331 21.0577C2.45944 18.0238 2.04953 13.5149 4.57776 10.9866L9.15209 15.561ZM15.3813 9.33864C18.5857 12.5431 18.7688 18.7688 15.7475 21.7901C12.5431 18.5857 12.36 12.36 15.3813 9.33864Z" fill="#7656FF"/>
  </svg>
</ng-template>
<ng-template #logo>
  <div class="signup-content" *transloco="let t">
    <app-logo routerLink="/home" iconColor="fff" textColor="fff" width="307" height="90"></app-logo>
    <div id="signup-content-title">
      <h1>
        {{t('signup-content-title')}}
      </h1>
    </div>
  </div>

</ng-template>
<ng-template #logoMobile>
  <div id="signup-mobile-header-logo">
    <app-logo routerLink="/home" iconColor="fff" textColor="fff" width="240" height="70"></app-logo>
  </div>
  <div id="signup-mobile-header-bg">
    <ng-container *ngTemplateOutlet="logoIcon"></ng-container>
  </div>
</ng-template>
<ng-template #sinupFormTemplate>
  <div id="register" class="signup-form" *transloco="let t">
    <h2>{{t('Create_your_account')}}</h2>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit($event)">
      <input formControlName="email"
             [attr.placeholder]="t('Email')"
             type="text"
             name="email"
             (input)="onEmailInput($event)"
             id="register-email">
      <div class="password-wrapper">
        <input formControlName="password"
               [attr.placeholder]="t('Password')"
               [attr.type]="hidePassword ? 'password' : 'text'"
               name="password"
               id="register-password">
        <app-icons class="hideIcon" [name]="hidePassword ? 'eye_1' : 'eye_closed'" fillColor="#929298" [width]="20" [height]="20" (click)="togglePassVisible()"></app-icons>
      </div>
      <div class="input-hint">
        {{t('password_hint')}}
      </div>
      <input formControlName="passwordConfirm"
             [attr.placeholder]="t('Confirm_your_password')"
             [attr.type]="hidePassword ? 'password' : 'text'"
             name="passwordConfirm"
             [class.ng-invalid]="registerForm.hasError('confirmedValidator') && registerForm.get('passwordConfirm').touched"
             id="register-password-confirm">
      <button type="submit" class="btn btn-primary capitalize" [disabled]="!nextEnabled" [ngClass]="{'disabled': !nextEnabled}">{{t('next')}}</button>
      <p>{{t('Already_have_an_account')}} <a [routerLink]="'/signin'">{{t('Log_in')}}</a></p>
    </form>
    <app-google-button></app-google-button>
    <p>
      {{t('By_signing_up_you_agree_to_our')}} <a routerLink="/terms-of-service">{{t('Terms_of_Service')}}</a> {{t('and')}} <a routerLink="/privacy-policy">{{t('Privacy_Policy')}}</a>, {{t('and_confirm_that_you_are_at_least_18_years_old')}}
    </p>
  </div>
</ng-template>
<ng-template #signInFormTemplate>
  <div id="login" class="signup-form" *transloco="let t">
    <h2>{{t('Login_to_your_account')}}</h2>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)">
      <input formControlName="email"
             [attr.placeholder]="t('Email')"
             type="text"
             name="email"
             (input)="onEmailInput($event)"
             id="login-email">
      <div class="password-wrapper">
        <input formControlName="password"
               [attr.placeholder]="t('Password')"
               [attr.type]="hidePassword ? 'password' : 'text'"
               name="password"
               id="login-password">
        <app-icons class="hideIcon" [name]="hidePassword ? 'eye_1' : 'eye_closed'" fillColor="#929298" [width]="20" [height]="20" (click)="togglePassVisible()"></app-icons>
      </div>
      <p>{{t('Forgot_your_password')}}  <a (click)="recoverPassword()">{{t('Recover')}}</a></p>
      <button type="submit" class="btn btn-primary capitalize" [disabled]="!nextEnabled" [ngClass]="{'disabled': !nextEnabled}">{{t('next')}}</button>
      <p>{{t('Dont_have_an_account')}} <a [routerLink]="'/signup'">{{t('Register')}}</a></p>
    </form>
    <app-google-button></app-google-button>
    <p>
      {{t('By_signing_up_you_agree_to')}} <a routerLink="/privacy-policy">{{t('Privacy_Policy')}}</a>, {{t('and_confirm_that_you_are_at_least_18_years_old')}}
    </p>
  </div>
</ng-template>

<app-dialog #recoverPasswordDialogRef (onClose)="closeRecoverDialog()">
  <app-recover-password #recoverPasswordRef (onClose)="closeRecoverDialog()"></app-recover-password>
</app-dialog>
