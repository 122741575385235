import {
  AfterContentInit,
  afterNextRender,
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterModule} from '@angular/router';
import {SsrCookieService} from "ngx-cookie-service-ssr";
import {IconsComponent} from "./uikit/icons/icons.component";
import {StorageDataKey, StorageService} from "./service/storage.service";
import {ApiService} from "./api/api.service";
import {CommonModule, isPlatformBrowser} from "@angular/common";
import {SocialAuthComponent} from "./components/social-auth/social-auth.component";
import {UserRepositoryService} from "./repository/user-repository.service";
import {UnauthorizedError, UserNotFoundError} from "./api/interceptors/error.interceptor";
import {MessagesApiService} from "./api/ws.service";
import {PaymentsService} from "./service/payments.service";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import * as uuid from 'uuid';
import {filter, Subscription, tap} from "rxjs";
import {RoutingService} from './service/routing.service';
import {NotificationsRepositoryService} from "./repository/notifications-repository.service";
import {ToastrService} from "ngx-toastr";
import {cloneDeep} from 'lodash';
import {
  NotificationToastComponent
} from "./modules/notifications/components/notification-toast/notification-toast.component";
import {UserModelData} from "desiren-core-lib/lib/types/users";
import {StyleInjectorService} from "./service/style-inject.service";
import {defaultMetadata, MetadataService} from "./service/meta.service";
import {environment} from "../environments/environment";
import {MediaPreviewDialogComponent} from "./components/media-preview-dialog/media-preview-dialog.component";
import {SessionService} from "./service/session.service";
import Smartlook from 'smartlook-client';
import {CookieDialogComponent} from "./components/cookie-dialog/cookie-dialog.component";
import {Dialog, DialogModule} from "@angular/cdk/dialog";
import {ScrollStrategyOptions} from "@angular/cdk/overlay";
import {getBrowserLang, TranslocoModule, TranslocoService} from "@ngneat/transloco";
import {AppLanguage} from "./transloco-loader";
import {EMessagesMessageNotificationChat} from "desiren-core-lib/lib";
import {ENotificationAction} from "desiren-core-lib/lib/enums/notifications/notification-action.enum";
import {IMessagesTextMessageResponse} from "desiren-core-lib/lib/types/messages/message/text.message.interface";
import {
  IMessagesMultipartMessageResponse
} from "desiren-core-lib/lib/types/messages/message/multipart.message.interface";
import {IMessagesPhotoMessageResponse} from "desiren-core-lib/lib/types/messages/message/photo.message.interface";
import {IMessagesDocumentMessageResponse} from "desiren-core-lib/lib/types/messages/message/document.message.interface";
import {IMessagesVoiceMessageResponse} from "desiren-core-lib/lib/types/messages/message/voice.message.interface";
import {IMessagesAudioMessageResponse} from "desiren-core-lib/lib/types/messages/message/audio.message.interface";
import {
  IMessagesMessagesVideoMessageResponse
} from "desiren-core-lib/lib/types/messages/message/video.message.interface";
import {IMessageResponse} from "desiren-core-lib/lib/types/messages/message/_.message.interface";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    IconsComponent,
    SocialAuthComponent,
    MediaPreviewDialogComponent,
    CookieDialogComponent,
    DialogModule,
  ],
  providers: [
    SsrCookieService,
    StorageService,
    ApiService,
    UserRepositoryService,
    MessagesApiService,
    SessionService,
    TranslocoModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  userSub$?: Subscription;
  routeSub$?: Subscription;
  showAuth = false;
  @ViewChild('loader') loader!: ElementRef<HTMLDialogElement>;

  @HostListener('window:load', ['$event'])
  onLoad() {
    if (this.storage.getLocalJsonData(StorageDataKey.apiUser) == null) {
      this.userRepo.meSubject.next(null);
    }
  }

  private wsNotifications$: Subscription;
  private wsMessageNotifications$: Subscription;
  public isBrowser: boolean = false;

  constructor(private cookie: SsrCookieService,
              private storage: StorageService,
              private payments: PaymentsService,
              private activeRoute: ActivatedRoute,
              private userRepo: UserRepositoryService,
              private socket: MessagesApiService,
              private router: Router,
              private zone: NgZone,
              private afMessaging: AngularFireMessaging,
              private urlService: RoutingService,
              private api: ApiService,
              private notifications: NotificationsRepositoryService,
              private toastr: ToastrService,
              private styleInjector: StyleInjectorService,
              @Inject(PLATFORM_ID) private platformId,
              private metadataService: MetadataService,
              private session: SessionService,
              private dialog: Dialog,
              private readonly sso: ScrollStrategyOptions,
              private transloco: TranslocoService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    let browserLang = getBrowserLang();
    if (Object.keys(AppLanguage).includes(browserLang)) {
      this.transloco.setActiveLang(browserLang);
    } else {
      this.transloco.setActiveLang(AppLanguage.en.toString());
    }
    afterNextRender(() => {
        this.showLoader();
      },
    );
    afterNextRender(() => {
      this.checkCookies();
      this.activeRoute.queryParamMap.pipe(tap(params => {
          params.has('refId');
        })).subscribe(data => {
        if (data.has('refId') && !this.storage.getLocalData(StorageDataKey.referrerCode)) {
          this.storage.setLocalData(StorageDataKey.referrerCode, this.activeRoute.snapshot.queryParams['refId']);
        }
      });
      if (environment.isProd && typeof window != undefined) {
        window.console.log = () => {};
        window.console.dir = () => {};
        window.console.error = () => {};
      }
    },);
  }

  ngOnInit() {
    this.requestPermission();
    this.routeSub$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.urlService.prevUrl = this.urlService.currentUrl;
      this.urlService.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.urlService.prevUrl);
    });
    this.wsNotifications$ = this.socket.notifications.subscribe(data => {
      const opt = cloneDeep(this.toastr.toastrConfig);
      opt.toastComponent = NotificationToastComponent;
      opt.toastClass = 'notification-toast';
      opt.payload = data;
      opt.extendedTimeOut = 6 * 60 * 60;
      this.toastr.show('', '', opt);
      this.notifications.unread();
    });
    this.wsMessageNotifications$ = this.socket.messageNotifications.pipe(
      filter((key) => [
        EMessagesMessageNotificationChat.NEW_MULTIPART_MESSAGE,
        EMessagesMessageNotificationChat.NEW_TEXT_MESSAGE,
        EMessagesMessageNotificationChat.NEW_PHOTO_MESSAGE,
        EMessagesMessageNotificationChat.NEW_DOCUMENT_MESSAGE,
        EMessagesMessageNotificationChat.NEW_VOICE_MESSAGE,
        EMessagesMessageNotificationChat.NEW_AUDIO_MESSAGE,
        EMessagesMessageNotificationChat.NEW_VIDEO_MESSAGE,
      ].includes(key.type)),
      filter((key) => this.urlService.currentUrl !== `/messages/chat/${(key.data as IMessageResponse).chatId}`)
    ).subscribe(data => {
      const opt = cloneDeep(this.toastr.toastrConfig);
      opt.toastComponent = NotificationToastComponent;
      opt.toastClass = 'notification-toast';
      if (data?.type === EMessagesMessageNotificationChat.NEW_MULTIPART_MESSAGE) {
        const message = data.data as IMessagesMultipartMessageResponse;
        opt.payload = data.data;
        opt.payload = {
          action: ENotificationAction.NEW_MULTIPART_MESSAGE,
          message: message,
        }
      }
      if (data?.type === EMessagesMessageNotificationChat.NEW_TEXT_MESSAGE) {
        const message = data.data as IMessagesTextMessageResponse;
        opt.payload = data.data;
        opt.payload = {
          action: ENotificationAction.NEW_TEXT_MESSAGE,
          message: message,
        }
      }
      if (data?.type === EMessagesMessageNotificationChat.NEW_PHOTO_MESSAGE) {
        const message = data.data as IMessagesPhotoMessageResponse;
        opt.payload = data.data;
        opt.payload = {
          action: ENotificationAction.NEW_PHOTO_MESSAGE,
          message: message,
        }
      }
      if (data?.type === EMessagesMessageNotificationChat.NEW_DOCUMENT_MESSAGE) {
        const message = data.data as IMessagesDocumentMessageResponse;
        opt.payload = data.data;
        opt.payload = {
          action: ENotificationAction.NEW_DOCUMENT_MESSAGE,
          message: message,
        }
      }
      if (data?.type === EMessagesMessageNotificationChat.NEW_VOICE_MESSAGE) {
        const message = data.data as IMessagesVoiceMessageResponse;
        opt.payload = data.data;
        opt.payload = {
          action: ENotificationAction.NEW_VOICE_MESSAGE,
          message: message,
        }
      }
      if (data?.type === EMessagesMessageNotificationChat.NEW_AUDIO_MESSAGE) {
        const message = data.data as IMessagesAudioMessageResponse;
        opt.payload = data.data;
        opt.payload = {
          action: ENotificationAction.NEW_AUDIO_MESSAGE,
          message: message,
        }
      }
      if (data?.type === EMessagesMessageNotificationChat.NEW_VIDEO_MESSAGE) {
        const message = data.data as IMessagesMessagesVideoMessageResponse;
        opt.payload = data.data;
        opt.payload = {
          action: ENotificationAction.NEW_VIDEO_MESSAGE,
          message: message,
        }
      }
      opt.extendedTimeOut = 6 * 60 * 60;
      this.toastr.show('', '', opt);
    })
    if (isPlatformBrowser(this.platformId)) {
      this.injectStyleAfterLoad();
    }
    this.metadataService?.updateMetadata(defaultMetadata);
    this.session.onLoggedInSub.subscribe(data => {
      if (data.isLoggedIn) {
        this.updateUser(data.returnUrl);
      }
    });
    if (environment.isProd) {
      Smartlook.init('1fe4feeca011116a1c7fa27e495a195a3b00bdfa');
      Smartlook.record({});
    }
  }

  ngAfterContentInit() {
    this.checkUser().then(() => this.closeLoader());
  }

  ngOnDestroy(): void {
    this.routeSub$.unsubscribe();
    this.wsNotifications$.unsubscribe();
  }

  requestPermission() {
    if (this.isBrowser) {
      setTimeout(() => {
        this.afMessaging.requestPermission.subscribe({
          next: () => {
            this.afMessaging.getToken.subscribe(token => {
              this.storage.setLocalData(StorageDataKey.fcmToken, token);
              this.afMessaging.messages.subscribe(messaging => {
                console.dir(messaging);
              });
            });
          },
          error: () => {
          },
        });
      }, 2000);
    }
  }

  checkCookies() {
    const allowCookie = this.cookie.check('allowCookie');
    if (!allowCookie) {
      setTimeout(() => {
        this.dialog.open(CookieDialogComponent, {
          hasBackdrop: false,
          maxWidth: '600px',
          height: '100%',
          panelClass: 'cookie-dialog-container',
          scrollStrategy: this.sso.noop(),
        });
      }, 5000);
    }
    if (!this.cookie.get('browserId').length) {
      this.cookie.set('browserId', uuid.v4(), {
        expires: (new Date().valueOf() / 1000) + 60 * 60 * 24 * 365,
      });
    }
    this.storage.setLocalData(StorageDataKey.browserId, this.cookie.get('browserId'));
  }

  private injectStyleAfterLoad() {
    window.addEventListener('load', () => {
      const styleContent = `
          #hubspot-messages-iframe-container {
            bottom: 78px !important;
            display: none !important;
          }
          @media screen and (min-width: 1024px) {
            #hubspot-messages-iframe-container {
              bottom: 0 !important;
              display: initial !important;
            }
          }
        `;

      setTimeout(() => {
        this.styleInjector.injectStyle(styleContent);
      }, 2000);
    });
  }

  async checkUser() {
    this.userSub$?.unsubscribe();
    const data = this.storage.getLocalData(StorageDataKey.jwtToken);
    if (data != undefined && data?.length > 0) {
      try {
        this.updateUser();
      } catch (e) {
        this.storage.clearLocalData(StorageDataKey.jwtToken);
        this.storage.clearLocalData(StorageDataKey.apiUser);
      }
    }
    if (!data) {
      this.userSub$ = this.userRepo.meSubject$.subscribe(data => {
        this.showAuth = data == null;
      });
    }
  }

  showLoader(): void {
    this.loader.nativeElement.show();
    this.loader.nativeElement.classList.add('opened');
  }

  closeLoader() {
    if (this.isBrowser) {
      setTimeout(() => {
        this.loader.nativeElement.close();
        this.loader.nativeElement.classList.remove('opened');
      }, 1000);
    }
  }

  updateUser(returnUrl?: string): void {
    this.userSub$?.unsubscribe();
    this.userRepo.me().then(user => {
      const userData = this.storage.getLocalJsonData(StorageDataKey.apiUser) as UserModelData | null;
      if (userData != null && userData?.isCreator != user.isCreator) {
        this.api.refreshToken({
          userId: this.userRepo.meLazy().cache.id,
          jwtToken: this.storage.getLocalData(StorageDataKey.jwtToken),
          fcmToken: this.storage.getLocalData(StorageDataKey.fcmToken),
          browserId: this.storage.getLocalData(StorageDataKey.browserId),
        }).then(data => {
          this.storage.setLocalJsonData(StorageDataKey.jwtToken, data.jwtToken);
        });
      }
      this.storage.setLocalJsonData(StorageDataKey.apiUser, user.toJson());
      if (user.onboardingData != null) {
        this.storage.setLocalJsonData(StorageDataKey.onboarding, user.onboardingData);
      }
      if (this.router.url.includes('signup') || this.router.url.includes('signin')) {
        this.zone.run(() => {
          this.router.navigate([returnUrl ?? '/home']);
        });
      }
      this.notifications.unread();
      this.payments.getProfileCards().future().finally();
      this.socket.onConnect();
    }, (e: any) => {
      if (e instanceof UnauthorizedError || e instanceof UserNotFoundError) {
        this.socket.disconnect();
        if (!(this.router.url.includes('signup') || this.router.url.includes('signin'))) {
          this.zone.run(() => {
            this.storage.clearLocalStorage();
            return this.router.navigate(['/signup'], {
              queryParams: {
                returnUrl: this.router.url
              }
            });
          });
        }
      }
    }).finally(() => {
      this.userSub$ = this.userRepo.meSubject$.subscribe(data => {
        this.showAuth = data == null;
      });
    });
  }
}
