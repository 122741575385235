import {AfterContentChecked, AfterViewInit, Component, inject, TemplateRef, ViewChild} from '@angular/core';
import {Toast} from "ngx-toastr";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {CommonModule} from "@angular/common";
import {
  NDonatedComponent,
  NInPostCommentComponent,
  NInPostTagComponent,
  NMessagePurchasedComponent,
  NNewMessageComponent,
  NNewPostCommentComponent,
  NNewPostComponent,
  NNewSubscriberComponent,
  NPostPurchasedComponent,
  NProfileApprovedComponent, NProfileRejectedComponent, NSubChangeToFreeComponent, NSubChangeToPayedComponent
} from "../notification/types";

import {Howl} from 'howler';
import {NotificationsRepositoryService} from "../../../../repository/notifications-repository.service";
import {NSubChangeComponent} from "../notification/types/n-sub-change/n-sub-change.component";
import {TNotification} from "@prestinly/core-lib/lib/types/notifications/notification.type";
import {ENotificationAction} from "@prestinly/core-lib/lib/enums/notifications/notification-action.enum";
import {
  INotificationsNewMessagesNotification
} from "@prestinly/core-lib/lib/types/notifications/content/new-messages.notification.interface";
import {
  INotificationsNewPostNotification
} from "@prestinly/core-lib/lib/types/notifications/content/new-post.notification.interface";
import {
  INotificationsNewPostCommentNotification
} from "@prestinly/core-lib/lib/types/notifications/content/new-post-comment.notification.interface";
import {
  INotificationsDonatedNotification
} from "@prestinly/core-lib/lib/types/notifications/purchases/donated.notification.interface";
import {
  INotificationsMessagePurchasedNotification
} from "@prestinly/core-lib/lib/types/notifications/purchases/message-purchased.notification.interface";
import {
  INotificationsPostPurchasedNotification
} from "@prestinly/core-lib/lib/types/notifications/purchases/post-purchased.notification.interface";
import {
  INotificationsChangedSubscriptionFromFreeToPaidNotification
} from "@prestinly/core-lib/lib/types/notifications/subscription/changed-subscription-from-free-to-paid.notification.interface";
import {
  INotificationsChangedSubscriptionFromPaidToFreeNotification
} from "@prestinly/core-lib/lib/types/notifications/subscription/changed-subscription-from-paid-to-free.notification.interface";
import {
  INotificationsNewSubscriberNotification
} from "@prestinly/core-lib/lib/types/notifications/subscription/new-subscriber.notification.interface";
import {
  INotificationsCreatorsProfileApprovedNotification
} from "@prestinly/core-lib/lib/types/notifications/system/creators-profile-approved.notification.interface";
import {
  INotificationsCreatorsProfileRejectedNotification
} from "@prestinly/core-lib/lib/types/notifications/system/creators-profile-rejected.notification.interface";
import {
  INotificationsInPostCommentTagNotification
} from "@prestinly/core-lib/lib/types/notifications/tags/in-post-comment-tag.notification.interface";
import {
  INotificationsInPostTagNotification
} from "@prestinly/core-lib/lib/types/notifications/tags/in-post-tag.notification.interface";

@Component({
  selector: '[app-notification-toast]',
  standalone: true,
  imports: [
    CommonModule,
    NInPostCommentComponent,
    NNewPostCommentComponent,
    NDonatedComponent,
    NInPostTagComponent,
    NMessagePurchasedComponent,
    NNewMessageComponent,
    NNewPostComponent,
    NNewSubscriberComponent,
    NPostPurchasedComponent,
    NProfileApprovedComponent,
    NProfileRejectedComponent,
    NSubChangeToFreeComponent,
    NSubChangeToPayedComponent,
    NSubChangeComponent,
  ],
  templateUrl: './notification-toast.component.html',
  styleUrl: './notification-toast.component.scss',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active',
        animate('400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              opacity: 0,
            }),
            style({
              transform: 'skewX(20deg)',
              opacity: 1,
            }),
            style({
              transform: 'skewX(-5deg)',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ]))),
      transition('active => removed',
        animate('400ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0) skewX(30deg)',
              opacity: 0,
            }),
          ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class NotificationToastComponent extends Toast implements AfterContentChecked, AfterViewInit {
  @ViewChild('creators_profile_approved') creators_profile_approved: TemplateRef<any>;
  @ViewChild('creators_profile_rejected') creators_profile_rejected: TemplateRef<any>;
  @ViewChild('creators_profile_expired') creators_profile_expired: TemplateRef<any>;
  @ViewChild('new_subscriber') new_subscriber: TemplateRef<any>;
  @ViewChild('changed_subscription_from_free_to_paid') changed_subscription_from_free_to_paid: TemplateRef<any>;
  @ViewChild('changed_subscription_from_paid_to_free') changed_subscription_from_paid_to_free: TemplateRef<any>;
  @ViewChild('changed_subscription_from_paid_to_paid') changed_subscription_from_paid_to_paid: TemplateRef<any>;
  @ViewChild('donated') donated: TemplateRef<any>;
  @ViewChild('post_purchased') post_purchased: TemplateRef<any>;
  @ViewChild('message_purchased') message_purchased: TemplateRef<any>;
  @ViewChild('in_post_tag') in_post_tag: TemplateRef<any>;
  @ViewChild('in_post_comment_tag') in_post_comment_tag: TemplateRef<any>;
  @ViewChild('new_post') new_post: TemplateRef<any>;
  @ViewChild('new_post_comment') new_post_comment: TemplateRef<any>;
  @ViewChild('new_messages') new_messages: TemplateRef<any>;

  notification: TNotification | null = null;
  notificationOutlet: TemplateRef<any> | null;

  sound = new Howl({
    src: ['assets/tweet_sent.ogg'],
    loop: false,
  });

  notificationsRepo = inject(NotificationsRepositoryService);

  action(event: any) {
    this.notificationsRepo.read([event as string]).then(_ => {
      this.notificationsRepo.unread();
    });
    this.toastPackage.triggerAction();
    return false;
  }

  ngAfterContentChecked(): void {
    if (this.notification != null && this.notificationOutlet != null) return;
    this.notification = this.options.payload;
    switch (this.notification?.action) {
      case ENotificationAction.KYC_APPROVED:
        this.notificationOutlet = this.creators_profile_approved;
        this.notification = this.options.payload as INotificationsCreatorsProfileApprovedNotification;
        break;
      case ENotificationAction.KYC_REJECTED:
        this.notificationOutlet = this.creators_profile_rejected;
        this.notification = this.options.payload as INotificationsCreatorsProfileRejectedNotification;
        break;
      case ENotificationAction.NEW_SUBSCRIBER:
        this.notificationOutlet = this.new_subscriber;
        this.notification = this.options.payload as INotificationsNewSubscriberNotification;
        break;
      case ENotificationAction.CHANGED_SUBSCRIPTION_FROM_FREE_TO_PAID:
        this.notificationOutlet = this.changed_subscription_from_free_to_paid;
        this.notification = this.options.payload as INotificationsChangedSubscriptionFromFreeToPaidNotification;
        break;
      case ENotificationAction.CHANGED_SUBSCRIPTION_FROM_PAID_TO_FREE:
        this.notificationOutlet = this.changed_subscription_from_paid_to_free;
        this.notification = this.options.payload as INotificationsChangedSubscriptionFromPaidToFreeNotification;
        break;
      case ENotificationAction.DONATED:
        this.notificationOutlet = this.donated;
        this.notification = this.options.payload as INotificationsDonatedNotification;
        break;
      case ENotificationAction.POST_PURCHASED:
        this.notificationOutlet = this.post_purchased;
        this.notification = this.options.payload as INotificationsPostPurchasedNotification;
        break;
      case ENotificationAction.MESSAGE_PURCHASED:
        this.notificationOutlet = this.message_purchased;
        this.notification = this.options.payload as INotificationsMessagePurchasedNotification;
        break;
      case ENotificationAction.IN_POST_TAG:
        this.notificationOutlet = this.in_post_comment_tag;
        this.notification = this.options.payload as INotificationsInPostTagNotification;
        break;
      case ENotificationAction.IN_POST_COMMENT_TAG:
        this.notificationOutlet = this.in_post_comment_tag;
        this.notification = this.options.payload as INotificationsInPostCommentTagNotification;
        break;
      case ENotificationAction.NEW_POST:
        this.notificationOutlet = this.new_post;
        this.notification = this.options.payload as INotificationsNewPostNotification;
        break;
      case ENotificationAction.NEW_POST_COMMENT:
        this.notificationOutlet = this.new_post_comment;
        this.notification = this.options.payload as INotificationsNewPostCommentNotification;
        break;
      case ENotificationAction.NEW_MESSAGES:
        this.notificationOutlet = this.new_messages;
        this.notification = this.options.payload as INotificationsNewMessagesNotification;
        break;
      default:
        return null;
    }
  }

  ngAfterViewInit() {
    this.sound.play();
  }
}
