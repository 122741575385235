<ng-container *transloco="let t;">
  <div class="cookie-dialog" [ngClass]="isOpened ? 'opened' : ''">
    <app-icons class="mobile-icon" name="cookie"></app-icons>
    <app-icons class="desktop-icon" name="cookie" width="80" height="80"></app-icons>
    <div class="cookie-dialog-text">
      {{t('cookie_dialog_text')}} <a routerLink="/cookie">{{t('here')}}</a>.
    </div>
    <button class="btn btn-primary" (click)="acceptAndClose()">{{t('accept_all')}}</button>
  </div>
</ng-container>
