<form [formGroup]="codeForm" *transloco="let t;">
  <span class="header-l text-bold">{{t('Enter_Code')}}</span>
  <div class="input-container">
    <span class="label input-label">{{t('send_recovery_code_text')}}</span>
    <div class="six-digit-code-container">
      <input *ngFor="let control of codeControls; let i = index" #codeInput type="text" mask="0" class="code-input"
        formControlName="{{i}}" maxlength="1" (input)="onInput(i, $event)" (paste)="onPaste($event)"
        (keydown)="onKeyDown(i, $event)">
    </div>
  </div>
</form>
<button type="submit" [disabled]="!isVerifyCodeValid" class="btn btn-primary capitalize" (click)="send()" *transloco="let t;">{{t('next')}}</button>
