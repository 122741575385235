import {Component, Input, ViewChild} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ProfileAvatarComponent} from "../../../../../../uikit/profile/profile-avatar/profile-avatar.component";
import {UserNameComponent} from "../../../../../../uikit/user-name/user-name.component";
import {IconsComponent} from "../../../../../../uikit/icons/icons.component";
import {RouterModule} from "@angular/router";
import {AppNotification, AppNotificationFrom} from "../../notification.component";
import {TranslocoModule} from "@ngneat/transloco";
import {
  INewTextMessageNotification
} from "desiren-core-lib/lib/types/notifications/message/new-text-message.notification.interface";

@Component({
  selector: 'app-n-new-text-message',
  standalone: true,
  imports: [
    CommonModule,
    ProfileAvatarComponent,
    UserNameComponent,
    IconsComponent,
    RouterModule,
    TranslocoModule
  ],
  templateUrl: './n-new-text-message.component.html',
  styleUrl: './n-new-text-message.component.scss'
})
export class NNewTextMessageComponent extends AppNotificationFrom {
  @ViewChild('notification') declare notification;

  @Input('data') declare data: INewTextMessageNotification;

  getMessageText(text: string): string {
    return text.replaceAll('<br/>', '');
  }
}
