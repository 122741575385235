import { Routes } from '@angular/router';
import { MainComponent } from "./components/main/main.component";
import { SignupComponent } from "./components/signup/signup.component";
import {TermsOfServiceComponent} from "./components/static/terms-of-service/terms-of-service.component";
import {PrivacyPolicyComponent} from "./components/static/privacy-policy/privacy-policy.component";
import {signupGuard} from "./guards/signup.guard";
import {authGuard} from "./guards/auth.guard";
import {isCreatorGuard} from "./guards/is-creator.guard";
import { CookiesComponent } from './components/static/cookies/cookies.component';
import { publicProfileGuard } from "./guards/public-profile.guard";

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home-routing.module').then(mod => mod.HomeRoutingModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then(mod => mod.AccountModule),
        canActivate: [authGuard],
      },
      {
        path: 'messages',
        loadChildren: () => import('./modules/messages/messages-routing.module').then(mod => mod.MessagesRoutingModule),
        canActivate: [authGuard],
      },
      {
        path: 'notifications',
        loadChildren: () => import('./modules/notifications/notifications-routing.module').then(mod => mod.NotificationsRoutingModule),
        canActivate: [authGuard],
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./modules/my-profile/my-profile-routing.module').then(mod => mod.MyProfileRoutingModule),
        canActivate: [authGuard],
      },
      {
        path: 'statements',
        loadChildren: () => import('./modules/statements/statements.module').then(mod => mod.StatementsRoutingModule),
        canActivate: [authGuard],
      },
      {
        path: 'statistics',
        loadChildren: () => import('./modules/statistics/statistics.module').then(mod => mod.StatisticsModule),
        canActivate: [authGuard],
      },
      {
        path: 'content',
        loadChildren: () => import('./modules/content/content-routing.module').then(mod => mod.ContentRoutingModule),
        canActivate: [authGuard, isCreatorGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings-routing.module').then(mod => mod.SettingsRoutingModule),
        canActivate: [authGuard],
      },
      {
        path: 'queue',
        loadChildren: () => import('./modules/queue/queue-routing.module').then(mod => mod.QueueRoutingModule),
        canActivate: [authGuard],
      }
    ]
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [signupGuard,],
  },
  {
    path: 'signin',
    component: SignupComponent,
    canActivate: [signupGuard,],
  },
  {
    path: 'profile',
    component: MainComponent,
    loadChildren: () => import('./modules/profile/profile-routing.module').then(mod => mod.ProfileRoutingModule),
  },
  {
    path: 'agency-onboarding',
    loadChildren: () => import('./modules/agency-onboarding/agency-onboarding.module').then(mod => mod.AgencyOnboardingRoutingModule),
    canActivate: [authGuard],
  },
  {
    path: 'creator/:id',
    component: MainComponent,
    loadChildren: () => import('./modules/public/public-routing.module').then(mod => mod.PublicRoutingModule),
    canActivate: [publicProfileGuard]
  },
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'cookie',
    component: CookiesComponent,
  }
];
