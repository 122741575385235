import {ApplicationConfig, isDevMode, LOCALE_ID} from '@angular/core';
import {PreloadAllModules, provideRouter, withPreloading} from '@angular/router';

import {routes} from './app.routes';
import {HAMMER_LOADER} from '@angular/platform-browser';
import {provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import {loggingInterceptor} from "./api/interceptors/logging.interceptor";
import {authInterceptor} from "./api/interceptors/auth.interceptor";
import {errorInterceptor} from "./api/interceptors/error.interceptor";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideToastr} from "ngx-toastr";
import {IMAGE_CONFIG, PRECONNECT_CHECK_BLOCKLIST, registerLocaleData} from "@angular/common";
import {environment} from "../environments/environment";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {FIREBASE_OPTIONS} from "@angular/fire/compat";
import {AcceptJSService, TK_CONFIG} from "@openutility/acceptjs-angular-wrapper";
import {of, skip} from "rxjs";
import {provideTransloco, TranslocoService} from "@ngneat/transloco";
import {AppLanguage, TranslocoHttpLoader} from './transloco-loader';

import en from '@angular/common/locales/en';
import ru from '@angular/common/locales/ru';
import ko from '@angular/common/locales/ko';

registerLocaleData(en);
registerLocaleData(ru);
registerLocaleData(ko);

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withFetch(),
      withInterceptors([
        loggingInterceptor,
        authInterceptor,
        errorInterceptor
      ])),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideAnimations(),
    provideToastr({
      toastClass: 'app-toast'
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    {
      provide: IMAGE_CONFIG, useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true
      },
    },
    {provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig},
    {provide: PRECONNECT_CHECK_BLOCKLIST, useValue: [environment.storeUrl, environment.storeOldUrl]},
    {provide: HAMMER_LOADER, useValue: () => import('hammerjs')},
    {
      provide: TK_CONFIG,
      useFactory: () => of(environment.acceptJsConfig)
    },
    AcceptJSService,
    provideHttpClient(),
    { provide: LOCALE_ID, useValue: 'en-EN' },
    provideTransloco({
        config: {
          availableLangs: Object.keys(AppLanguage),
          reRenderOnLangChange: true,
          defaultLang: 'en',
          prodMode: !isDevMode(),
          missingHandler: {
            logMissingKey: true,
          },
        },
        loader: TranslocoHttpLoader
      }),
  ]
};
