import { Component } from '@angular/core';
import { RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {IconsComponent} from "../../uikit/icons/icons.component";
import {NavigatorComponent} from "../navigator/navigator.component";



@Component({
  selector: 'app-main',
  standalone: true,
  imports: [RouterModule, CommonModule, IconsComponent, NavigatorComponent],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {
}
