import {Inject, inject, Injectable, LOCALE_ID} from "@angular/core";
import { Translation, TranslocoLoader } from "@jsverse/transloco";
import { HttpClient } from "@angular/common/http";
import {environment} from "../environments/environment";

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    private http = inject(HttpClient);

    getTranslation(lang: string) {
        return this.http.get<Translation>(`${environment.webUrl}/assets/i18n/${lang}.json?${new Date().getTime()}`);
    }
}

export enum AppLanguage {
  'en' = 'en',
  // 'ko' = 'ko',
  'ru' = 'ru'
}
