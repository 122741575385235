<ng-container>
  <div class="dialog-content" *transloco="let t;">
    <div class="dialog-header">
      <span class="header-l">{{t('Password_Recovery')}}</span>
    </div>
      <div class="dialog-body">
        <ng-container *ngTemplateOutlet="currentStepTemplate || email"></ng-container>
      </div>
  </div>
</ng-container>

<ng-template #email>
  <ng-container *transloco="let t;">
    <form [formGroup]="emailForm">
      <div class="input-container">
        <span class="label input-label">{{t('pass_recovery_text')}}</span>
        <input formControlName="email" noSpaces type="text" maxlength="150" placeholder="Email">
      </div>
    </form>
    <button type="submit" [disabled]="emailForm.invalid" class="btn btn-primary" (click)="sendEmail()">{{t('Submit')}}</button>
  </ng-container>
</ng-template>
<ng-template #code>
  <ng-container>
    <app-six-digit-code-input [isVerifyCodeValid]="isVerifyCodeValid" (codeEntered)="handleCodeEntered($event)"></app-six-digit-code-input>
  </ng-container>
</ng-template>
<ng-template #password>
  <form [formGroup]="passwordForm" *transloco="let t;">
    <div class="input-container">
      <span class="label action-text text-bold">{{t('New_Password')}}</span>
      <div class="suffix-container">
        <span class="suffix" (click)="isPasswordVisible = !isPasswordVisible">
          <app-icons [name]="isPasswordVisible ? 'eye_closed' : 'eye_1'" [width]="28" [height]="28" [fillColor]="'#929298'"></app-icons>
        </span>
        <input formControlName="password" noSpaces [type]="isPasswordVisible ? 'password' : 'text'" >
        <div class="hint-wrapper">
          <span *ngIf="passwordForm.get('password').hasError('formatError') &&
                passwordForm.get('password').dirty &&
                passwordForm.get('password').touched"
                class="error-message attention-text">
                {{t('new_password_hint')}}
        </span>
        </div>
      </div>
    </div>
    <div class="input-container">
      <span class="label action-text text-bold">{{t('Confirm_new_password')}}</span>
      <div class="suffix-container">
        <span class="suffix" (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible">
          <app-icons [name]="isConfirmPasswordVisible ? 'eye_closed' : 'eye_1'" [width]="28" [height]="28" [fillColor]="'#929298'"></app-icons>
        </span>
        <input formControlName="confirmPassword"
              noSpaces
              [type]="isConfirmPasswordVisible ? 'password' : 'text'"
              [class.invalid]="passwordForm.hasError('passwordNotMatch') &&
              passwordForm.get('confirmPassword').dirty &&
              passwordForm.get('confirmPassword').touched">
        <div class="hint-wrapper">
          <span *ngIf="passwordForm.hasError('passwordNotMatch') &&
                passwordForm.get('confirmPassword').dirty &&
                passwordForm.get('confirmPassword').touched"
                class="error-message attention-text">{{t('Passwords_do_not_match')}}</span>
        </div>
      </div>
    </div>
</form>
  <button type="submit" [disabled]="passwordForm.invalid" class="btn btn-primary" (click)="createNewPassword()" *transloco="let t;">{{t('Recover')}}</button>
</ng-template>
<ng-template #done>
  <div class="invitation" *transloco="let t;">
    <img src="../../../../../assets/png/diadem-heart.png" alt="diadem-heart">
    <span class="extra-large">
      {{t('Your_password_has_been')}}  <span class="text-color-primary"> {{t('successfully_recovered')}}</span>
    </span>
    <button type="submit" class="btn btn-primary" (click)="onClose.emit()">{{t('Lets_continue')}}</button>
  </div>
</ng-template>
