import {AfterViewInit, Component, inject} from '@angular/core';
import {SsrCookieService} from "ngx-cookie-service-ssr";
import {IconsComponent} from "../../uikit/icons/icons.component";
import {CommonModule} from "@angular/common";
import {Dialog, DialogModule} from "@angular/cdk/dialog";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-cookie-dialog',
  standalone: true,
  imports: [
    IconsComponent,
    CommonModule,
    DialogModule,
    TranslocoModule,
  ],
  providers: [
    SsrCookieService
  ],
  templateUrl: './cookie-dialog.component.html',
  styleUrl: './cookie-dialog.component.scss'
})
export class CookieDialogComponent implements AfterViewInit {
  private cookie = inject(SsrCookieService);
  private dialog = inject(Dialog);
  isOpened = false;

  ngAfterViewInit() {
    this.isOpened = true;
  }

  acceptAndClose() {
    this.dialog.closeAll();
    this.cookie.set('allowCookie', '1');
  }
}
