import {afterNextRender, Component, inject, OnInit, OnDestroy, ViewChild, HostListener} from '@angular/core';
import {CommonModule} from "@angular/common";
import {LogoComponent} from "../../uikit/logo/logo.component";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {IconsComponent} from "../../uikit/icons/icons.component";
import {ApiService} from "../../api/api.service";
import {StorageDataKey, StorageService} from "../../service/storage.service";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {UserRepositoryService} from "../../repository/user-repository.service";
import {UnauthorizedError, UserExistsError, UserNotFoundError, ValidationError} from "../../api/interceptors/error.interceptor";
import {ToastrModule, ToastrService} from "ngx-toastr";
import {MessagesApiService} from "../../api/ws.service";
import {PaymentsService} from "../../service/payments.service";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {SsrCookieService} from "ngx-cookie-service-ssr";
import {NotificationsRepositoryService} from "../../repository/notifications-repository.service";
import {Subscription} from "rxjs";
import {DialogComponent} from 'src/app/uikit/dialog/dialog.component';
import {RecoverPasswordComponent} from '../recover-password/recover-password.component';
import {PostsService} from "../../service/posts.service";
import {GoogleButtonComponent} from "../social-auth/social-auth.component";
import {EventsTrackerService} from "../../service/events-tracker.service";
import {TranslocoModule} from "@ngneat/transloco";

enum SignMode {login, register}

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    LogoComponent,
    IconsComponent,
    ToastrModule,
    DialogComponent,
    RecoverPasswordComponent,
    GoogleButtonComponent,
    TranslocoModule,
  ],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss'
})
export class SignupComponent implements OnInit, OnDestroy {
  private formBuilder = inject(FormBuilder);
  private api = inject(ApiService);
  private userRepo = inject(UserRepositoryService);
  private storage = inject(StorageService);
  private router = inject(Router);
  private activeRoute = inject(ActivatedRoute);
  private toastr = inject(ToastrService);
  private socket = inject(MessagesApiService);
  private payments = inject(PaymentsService);
  private afMessaging = inject(AngularFireMessaging);
  private cookie = inject(SsrCookieService);
  private notifications = inject(NotificationsRepositoryService);
  private postsService = inject(PostsService);
  private trackerService = inject(EventsTrackerService);
  private firebase$?: Subscription;
  private returnUrl?: string;
  mode: SignMode = SignMode.login;
  hidePassword: boolean = true;
  loginForm: FormGroup = this.formBuilder.group({
    email: new FormControl('', {
      validators: [
        Validators.required,
        Validators.email,
      ]
    }),
    password: new FormControl('', {
      validators: [
        Validators.required
      ],
    }),
  });
  registerForm: FormGroup = this.formBuilder.group({
    email: new FormControl('', {
      validators: [
        Validators.required,
        Validators.email,
      ]
    }),
    password: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(8),
      ],
    }),
    passwordConfirm: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(8),
      ],
    }),
  }, {
    validators: [
      this.ConfirmedValidator(),
    ],
    updateOn: 'change',
  });


  @ViewChild('recoverPasswordDialogRef') recoverPasswordDialogRef: DialogComponent | null = null;
  @ViewChild('recoverPasswordRef') recoverPasswordRef: RecoverPasswordComponent | null = null;

  @HostListener('window:load', ['$event'])
  onLoad() {
    this.userRepo.meSubject.next(null);
  }

  constructor() {
    if (this.router.url.includes('/signup')) {
      this.mode = SignMode.register;
    } else {
      this.mode = SignMode.login;
    }
    afterNextRender(() => {
        if (this.activeRoute.snapshot.queryParamMap.has('refId')) {
          this.storage.setLocalData(StorageDataKey.referrerCode, this.activeRoute.snapshot.queryParams['refId']);
        }
        if (this.activeRoute.snapshot.queryParamMap.has('returnUrl')) {
          this.returnUrl = this.activeRoute.snapshot.queryParamMap.get('returnUrl');
        }
      },
    );
  }

  onSubmit(event: Event) {
    const form = this.mode == SignMode.login ? this.loginForm : this.registerForm;
    if (this.mode == SignMode.login) {
      this.trackerService.signin(
        form.value['email']!.toString(),
        this.returnUrl,
      )
    } else {
      this.trackerService.signup(
        form.value['email']!.toString(),
        this.returnUrl,
      )
    }
    if (form.valid) {
      this._submitForm();
    }
  }

  _submitForm() {
    const form = this.mode == SignMode.login ? this.loginForm : this.registerForm;
    this.storage.setLocalData(StorageDataKey.browserId, this.cookie.get('browserId'));
    (this.mode == SignMode.login ? this.api.login(
        {
          email: form.value['email']!.toString(),
          password: form.value['password']!.toString(),
          referrerCode: this.storage.getLocalData(StorageDataKey.referrerCode) ?? '',
          fcmToken: this.storage.getLocalData(StorageDataKey.fcmToken) ?? null,
          browserId: this.storage.getLocalData(StorageDataKey.browserId) ?? '',
        }
      ) : this.api.register(
        {
          email: form.value['email']!.toString(),
          password: form.value['password']!.toString(),
          referrerCode: this.storage.getLocalData(StorageDataKey.referrerCode) ?? '',
          fcmToken: this.storage.getLocalData(StorageDataKey.fcmToken),
          browserId: this.storage.getLocalData(StorageDataKey.browserId) ?? '',
        }
      )
    ).then(response => {
      this.storage.clearLocalData(StorageDataKey.referrerCode);
      this.storage.setLocalData(StorageDataKey.jwtToken, response.jwtToken);
      this.userRepo.me().then(user => {
        this.payments.getProfileCards().future().finally();
        this.notifications.unread();
        this.storage.setLocalJsonData(StorageDataKey.apiUser, user.toJson());
        this.postsService.clearPosts();
        this.router.navigate([this.returnUrl ?? '/home']);
        this.socket.onConnect();
        if (user.onboardingData != null) {
          this.storage.setLocalJsonData(StorageDataKey.onboarding, user.onboardingData);
        }
      });
    }, (e: any) => {
      if (e instanceof ValidationError) {
        this.toastr.error(e.message);
        return;
      }
      if (e instanceof UserExistsError) {
        this.toastr.error('User with same email already exists');
        return;
      }
      if (e instanceof UserNotFoundError) {
        this.toastr.error('Email or password incorrect, maybe user is not exist');
        return;
      }
      if (e instanceof UnauthorizedError) {
        this.toastr.error('Wrong email or password');
        return;
      }
      if (e.error?.message === 'Unauthorized') {
        this.toastr.error('Wrong email or password');
        return;
      }
    });
  }

  togglePassVisible() {
    this.hidePassword = !this.hidePassword;
  }

  get nextEnabled(): boolean {
    return (
        this.mode == SignMode.register && this.registerForm.valid
      ) ||
      (
        this.mode == SignMode.login && this.loginForm.valid
      );
  }

  ConfirmedValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = this.registerForm?.get('password');
      const passwordConfirm = this.registerForm?.get('passwordConfirm');
      if (
        (passwordConfirm?.errors &&
          !passwordConfirm?.errors['confirmedValidator']) || (password?.errors &&
          !password?.errors['confirmedValidator'])
      ) {
        return {confirmedValidator: true};
      }
      if (password?.value !== passwordConfirm?.value) {
        return {confirmedValidator: true};
      } else {
        return null;
      }
    };
  }

  public ngOnInit(): void {
    this.firebase$ = this.afMessaging.getToken.subscribe(token => {
      if (token != null) {
        this.storage.setLocalData(StorageDataKey.fcmToken, token);
      }
    });
    this.userRepo.meSubject.next(null);
  }

  public onEmailInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const newValue = inputElement.value.replace(/\s/g, '');
    this.loginForm.get('email')?.setValue(newValue);
    this.registerForm.get('email')?.setValue(newValue);
  }

  ngOnDestroy() {
    this.firebase$.unsubscribe();
  }

  public closeRecoverDialog() {
    if (this.recoverPasswordRef) {
      this.recoverPasswordDialogRef.close()
      this.recoverPasswordRef.resetState();
    }
  }

  public recoverPassword() {
    this.recoverPasswordDialogRef.open();
  }

}
