<ng-container *ngTemplateOutlet="toast"></ng-container>
<ng-template #toast>
  <div class="notification toast">
    <div class="notification_type">
      <app-icons name="favorite" width="20" height="20" fillColor="#6A49FA"></app-icons>
    </div>
    <app-profile-avatar (click)="navigateToProfile()" size="notification" [url]="fromAvatar?.url"></app-profile-avatar>
    <div class="notification_body">
      <div class="notification_body-text" *transloco="let t;">
        <span *ngIf="data.message?.text">{{getMessageText(data.message.text)}}</span>
        <span *ngIf="!data.message?.text">{{t('Notification_new_document_message')}}</span>
      </div>
    </div>
    <app-icons name="close_1" class="notification_delete" width="40" height="40" fillColor="#DEDEEB" (click)="onRead.emit(data.id)"></app-icons>
  </div>
</ng-template>
